import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'
import laptop from '../assets/images/laptop-phone.jpg'
import googlePlay from '../assets/images/google-play-app.png'
import querystring from 'querystring'
import { OutboundLink } from 'gatsby-plugin-google-analytics'


class Generic extends React.Component {
  render() {
    let referrer = '';
    const query = typeof document !== `undefined` ? document.location.search : '';
    if (query) {
      const params = querystring.parse(query.replace('?', ''));
      if (params) {
        if (params.referrer) {
          referrer = encodeURIComponent(params.referrer);
        }
      }
    }

    return (
      <Layout>
        <Helmet title="Memz One - Hierarchical Notepad - Learn More" />
        <HeaderGeneric />
        <div id="main">
          <section id="content" className="main">
            <span className="image main"><img src={laptop} alt="Supersize your productivity with Memz One" /></span>
            <h2>Absolutely Free and Ads Free</h2>
            <p>
              Memz One is a Hierarchical Notepad, Outliner, Personal Organiser, Password Manager and Rich Text Editor.
              <br />
              In short - Ultimate Productivity Tool.
            </p>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '10mm',
              flexWrap: 'wrap',
            }}>
              <span style={{ width: '90mm', textAlign: 'center' }}>
                <OutboundLink href={`https://play.google.com/store/apps/details?id=au.com.ds.mo${referrer ? `&referrer=${referrer}` : ''}`}
                  target="_blank"
                  style={{ borderBottom: 'none' }}>
                  <img src={googlePlay} style={{ width: '80%' }} />
                </OutboundLink>
              </span>
              <span style={{ width: '10%' }}>OR</span>
              <span className="button special" style={{ width: '90mm', fontWeight: 'bold' }}>
                <OutboundLink href={`https://one.memz.co${referrer ? `#referrer:${referrer}` : ''}`}
                  target="_blank"
                  style={{ borderBottom: 'none' }}
                >
                  Launch Memz One in browser
              </OutboundLink>
              </span>
            </div>

            <h2>How is it different?</h2>
            <p>
              What differs Memz One from majority of similar tools is first-class hierarchy support.
              It simplifies not only creation of hierarchical notes but also maintaining them, moving from one level to another.
            </p>

            <h2>Boost your productivity</h2>
            <ul>
              <li>Whether you are on the go or in the office, capture your ideas, tasks or requirements with ease and elegance</li>
              <li>Develop captured notes into project</li>
              <li>Split projects into actionable tasks</li>
              <li>Track your progress</li>
              <li>Build knowledge base, customer database or inventory</li>
              <li>Get things done!</li>
            </ul>

            <div className="videoWrapper">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/NUZCLrmrGfU"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
            </div>

            <h2>Main features</h2>
            <ul>
              <li>Real-time sync</li>
              <li>Unlimited Hierarchy / Tree Structure support</li>
              <li>Rich Text support</li>
              <li>AES 256 bit encryption to protect your information</li>
              <li>Easy to use</li>
              <li>Multiplatform - works on most devices</li>
              <li>Easy import and export</li>
            </ul>

            <h2>Built-in rich text processing engine support many features like</h2>
            <ul>
              <li>Bullet lists</li>
              <li>Headers</li>
              <li>Paragraphs</li>
              <li>Links</li>
              <li>Bold, italic, linethrough, underlined, marked and code text</li>
              <li>Code blocks</li>
              <li>Block quotes</li>
              <li>Tables and more</li>
              <li>Simple and easy to use Markdown syntax</li>
            </ul>

            <p>
              Have you ever struggled trying to organize and outline your notes, ideas and projects, give them proper structure using conventional notes application?<br />
              Have you ever wished you could organize and outline your notes, ideas and projects the way you organize files and folders on your computer?<br />
              If you have, suffer no longer. Memz One is the outliner app you were looking for.<br />
            </p>

            <h2>Our users</h2>
            <ul>
              <li>Businessmen</li>
              <li>Project managers</li>
              <li>Priests</li>
              <li>Teachers</li>
              <li>Students</li>
              <li>Writers</li>
              <li>Scientists</li>
            </ul>

            <p>
              With Memz One your note taking &amp; information management needs will be met, both now and well into the future! Download Memz One today!
            </p>
          </section>
        </div>
      </Layout >
    )
  }
}

export default Generic
