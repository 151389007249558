import React from 'react'

const HeaderGeneric = (props) => (
    <header id="header">
        <h1>Memz One</h1>
        <p>Hierarchical Notepad for high achievers</p>
    </header>
)

export default HeaderGeneric
